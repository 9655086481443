export default {
  common: {
    play: "OYNAT",
    songs: "Müzikler",
  },
  nav: {
    home: "Anasayfa",
    explore: "Keşfet",
    library: "Kitaplık",
    search: "Ara",
    github: "GitHub Repo",
  },
  footer: {
    settings: "Ayarlar",
  },
  home: {
    recommendPlaylist: "Önerilen Çalma Listeier",
    recommendArtist: "Önerilen Sanatçılar",
    newAlbum: "Son Çıkan Albümler",
    seeMore: "DAHA FAZLASI",
    charts: "Listeler",
  },
  library: {
    sLibrary: "'in Kütüphanesi",
    likedSongs: "Beğenilen Müzikler",
    sLikedSongs: "'in Beğendiği Müzikler",
    playlists: "Çalma Listeleri",
    albums: "Albümler",
    artists: "Sanatçılar",
    mvs: "MVs",
    newPlayList: "Yeni Çalma Listesi",
    userProfileMenu: {
      settings: "Ayarlar",
      logout: "Çıkış Yap",
    },
  },
  explore: {
    explore: "Keşfet",
    loadMore: "Daha Fazlası",
  },
  artist: {
    latestRelease: "Son Çıkanlar",
    popularSongs: "Popüler Müzikler",
    showMore: "Daha Fazlası",
    showLess: "Daha Azı",
    EPsSingles: "EPs & Singles",
    albums: "Albümler",
    withAlbums: "Albümler",
    artist: "Sanatçı",
    videos: "Müzik Videoları",
    following: "Takip Ediyor",
    follow: "Takip Et",
  },
  album: {
    released: "Yayınlandı",
  },
  playlist: {
    playlist: "Çalma Listeleri",
    updatedAt: "Tarihinde Güncellendş",
    search: "Çalma Listesinde Ara",
  },
  login: {
    accessToAll: "Tüm verilere eriş",
    loginText: "Netease'e giriş yap",
    search: "Hesap ara",
    readonly: "Sadece halka açık verilere erişir",
    usernameLogin: "Kullanıcı adı giriş",
    searchHolder: "Hesabının kullanıcı adı",
    enterTip: "Aramak için 'enter'e basınız",
    choose: "Hesabını seç",
    confirm: "Onayla",
    countryCode: "Ülke kodu",
    phone: "Telefon",
    email: "Email adresi",
    password: "Şifre",
    login: "Giriş Yap",
    loginWithEmail: "Email ile giriş yap",
    loginWithPhone: "Phone ile giriş yap",
    notice: `YesPlayMusic hesabınızın hiçbir bilgisini kaydetmeyeceğine dair söz veriyor<br />
      Şifren MD5 şifreleme ile yerel olarak şifrelenir ve daha sonra NetEase Müzik API'sine gönderilir<br />
      YesPlayMusic, NetEase Music'in resmi websitesi değildir, lütfen hesap bilgilerinizi girmeden önce dikkatlice düşününüz. Aynı zamanda, Kendi NetEase Musix API'nızı host etmek için <a href="https://github.com/qier222/YesPlayMusic">YesPlayMusic'in GitHub Repo'suna</a> gidebilirsiniz.`,
    noticeElectron: `YesPlayMusic hesabınızın hiçbir bilgisini kaydetmeyeceğine dair söz veriyor<br />
      Şifren MD5 şifreleme ile yerel olarak şifrelenir ve daha sonra NetEase Müzik API'sine gönderilir<br />`,
  },
  mv: {
    moreVideo: "Daha Fazla Video",
  },
  next: {
    nowPlaying: "Şuan çalıyor",
    nextUp: "Sıradaki",
  },
  player: {
    like: "Beğen",
    previous: "Önceki Müzik",
    next: "Sonraki Müzik",
    repeat: "Tekrarla",
    repeatTrack: "Parçayı Tekrarla",
    shuffle: "Karıştır",
    play: "Oynat",
    pause: "Durdur",
    mute: "Sesi kapat",
    nextUp: "Sıradaki",
  },
  modal: {
    close: "Kapat",
  },
  search: {
    artist: "Sanatçılar",
    album: "Albümler",
    song: "Müzikler",
    mv: "Müzik Videoları",
    playlist: "Çalma Listeleri",
    noResult: "Sonuç Bulunamadı",
    searchFor: "Search for",
  },
  settings: {
    settings: "Ayarlar",
    logout: "ÇIKIŞ YAP",
    language: "Diller",
    musicQuality: {
      text: "Müzik Kalitesi",
      low: "Düşük",
      medium: "Orta",
      high: "Yüksek",
      lossless: "Kaliteli",
    },
    lyricFontSize: {
      text: "Şarkı Sözleri Yazı Boyutu",
      small: "Küçük",
      medium: "Orta",
      large: "Büyük(Varsayılan)",
      xlarge: "Çok-Büyük",
    },
    deviceSelector: "Ses Çıkış Cihazı",
    permissionRequired: "Mikrofon izni gerekiyor",
    appearance: {
      text: "Görünüş",
      auto: "Otomatik",
      light: "Aydınlık",
      dark: "Karanlık",
    },
    automaticallyCacheSongs: "Müzikleri otomatik çerezle",
    clearSongsCache: "Müzik çerezlerini temizle",
    cacheCount: "Çerezlenen {song} Müzikler ({size})",
    showLyricsTranslation: "Müzik sözlerinin çevirilerini göster",
    showLyricsDynamicBackground: "Dinamik arkaplanda müzik sözlerini gsöter",
    minimizeToTray: "Küçült",
    showGitHubIcon: "GitHub ikonunu göster",
    showUnavailableSongInGreyStyle: "Erişilemeyen müzikleri gri stilde göster",
    showPlaylistsByAppleMusic: "Apple Music'in Çalma Listelerini Göster",
    enableDiscordRichPresence: "Discord gösterimini aktifleştir",
  },
  contextMenu: {
    play: "Oynat",
    playNext: "Sonrakini Oynat",
    saveToMyLikedSongs: "Beğendiğim Müziklere Kaydet",
    removeFromMyLikedMüzikler: "Beğendiğim Müziklerden Kaldır",
  },
  toast: {
    savedToMyLikedSongs: "Beğendiğim Müziklere Kaydet",
    removedFromMyLikedSongs: "Beğendiğim Müziklerden Kaldır",
  },
};
